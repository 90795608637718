import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

const Salaries = () => {
    return (
        <section className="solutions-area ptb-70 bg-wspk">
            <div className="container">
                <div className="row align-items-center pb-30">
                    <div className="col-lg-12 col-md-12 pt-70 pb-30 center">
                        <h2 className="subtitle">Vous êtes salarié, collaborateur</h2>
                    </div>

                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>Accédez à votre compte pour déposer un signalement, suivre le traitement, échanger avec le référent en mode anonyme ou confidentiel.</p>
                                    <Link to="https://agir.wispeek.com" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Espace salarié <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>Vous ne souhaitez pas créer de compte. Ce formulaire sera chiffré et envoyé au référent. Votre identité vous sera demandé.</p>
                                    <Link to="https://gerer.wispeek.com/s/3quiPDPshkBw4dKrt" target="_blank" className="default-btn" style={{backgroundColor:'coral'}}>
                                        <i className="flaticon-right"></i> 
                                        Déclarer un signalement <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                <div className="faq-accordion pt-50 pb-70">
                    <Accordion allowZeroExpanded>
                            
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Pourquoi se créer un compte ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p><span className='bold'>La création du compte est anonyme :</span> votre adresse mail et votre mot de passe ne sont pas enregistrés et communiqués. Le code entreprise vous est fourni par votre organisation.</p>
                            <p className='bold'>Créez votre compte</p>
                            <p>Cette interface est personnelle, seul vous pouvez y accéder. Faites attention de bien retenir votre mot de passe et de ne le divulguer à personne.</p>

                            <p>Votre compte vous permet de :
                                <ul>
                                    <li>Faire des signalements et des remontées d’information en mode confidentiel ou anonyme</li>
                                    <li>Discuter via une messagerie sécurisée avec le référent en charge de votre dossier et d’échanger tous types de documents</li>
                                    <li>Consulter les documents mis à votre disposition (charte de l’entreprise, procédures, formulaires, etc.).</li>
                                    <li>Conservez bien votre mot de passe, comme les données sont chiffrées, nous ne pouvons pas le renouveller.</li>
                                </ul>
                            </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                            
                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Engagement Protection et Responsabilité ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p>Wispeek est une plateforme de signalement interne conforme aux obligations légales de protection des lanceurs d’alerte prévues par les lois Sapin 2, Waserman ou encore le Devoir de Vigilance.</p>
                            <p><span className='bold'>Définition :</span> un lanceur d'alerte est une personne physique qui signale ou divulgue, sans contrepartie financière directe et de bonne foi, des informations portant sur un crime, un délit, une menace ou un préjudice pour l'intérêt général, une violation ou une tentative de dissimulation d'une violation d'un engagement international régulièrement ratifié ou approuvé par la France, d'un acte unilatéral d'une organisation internationale pris sur le fondement d'un tel engagement, du droit de l'Union européenne, de la loi ou du règlement. Lorsque les informations n'ont pas été obtenues dans le cadre des activités professionnelles mentionnées au I de l'article 8, le lanceur d'alerte doit en avoir eu personnellement connaissance.</p>
                            <p><span className='bold'>Procédure :</span> Dès l’envoi de votre signalement, en mode confidentiel ou anonyme, vous recevez automatiquement un récépissé. Votre entreprise doit ensuite vous répondre par écrit, via la messagerie sécurisée, dans un délai de 3 mois pour vous informer sur le suivi de votre signalement.</p>
                            <p><span className='bold'>Protection des lanceurs d’alerte :</span> Wispeek garantit la confidentialité de votre identité ainsi que celles des personnes mentionnées et des informations que vous serez amené à révéler.</p>
                            <p className='pt-30'>Nous vous encourageons à révéler votre identité afin de fluidifier le processus et garantir votre protection. Toutefois, si vous souhaitez rester anonyme, Wispeek garantit votre anonymat.</p>
                            <p>Afin de lutter contre les risques de représailles, vos échanges sont chiffrés et seul le correspondant peut les consulter. Lors d’un signalement recevable, le lanceur d’alerte est protégé contre les mesures de représailles disciplinaires internes et les condamnations civiles ou pénales.</p>
                            <p><span className='bold'>Alerte abusive et diffamation :</span> Nous vous rappelons que les informations que vous donnez sur vous-même, vos collègues ou tout aspect des activités de l'entreprise peuvent affecter des tiers. Veuillez noter à cet égard qu’une alerte faite de mauvaise foi peut donner lieu à des sanctions disciplinaires et des poursuites judiciaires.</p>
                            <p>Votre organisation a choisi de mettre en place un dispositif d’alertes professionnelles qui respecte les normes de protections des données personnelles établies par le <span className='bold'>RGPD</span> et la <span className='bold'>CNIL</span>.</p>
                            <p>Vous pouvez exercer les droits qui vous sont accordés par la réglementation, pour ce faire, veuillez consulter votre société pour en connaître les modalités.</p>
                            <p>Toute personne dont les données personnelles sont collectées et traitées dans le cadre du dispositif d’alerte dispose notamment du droit de s’opposer au traitement de ses données personnelles pour des raisons tenant à sa situation particulière. Nous attirons néanmoins votre attention sur le fait que ce droit d’opposition ne peut être exercé pour empêcher notre société de remplir ses obligations légales en matière de traitement des signalements et de protection de l’auteur d’un signalement.</p>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>         
                </div>
            </div>
        </section>
    )
}

export default Salaries;